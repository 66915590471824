body {
  margin: 0px;
  padding: 0px;
  font-family:'Open Sans', '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202124;
  height: 100%;
  width: 100%;
  font-size:14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.login-box
{
  border:solid 1px #dadce0;
  border-radius:8px;
  padding: 40px;
  box-sizing:border-box;
  width:400px
}

.login-page
{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-content: center;
  align-items:center;
  min-height:100vh;
}



.MuiFormLabel-root
{
  font-size:14px !important;
}

.MuiInput-input
{
  font-size: 14px !important;
}

.MuiInputLabel-formControl
{
  font-size: 14px !important;
}

.text-center
{
  text-align:center;
}

.mt-1
{
  margin-top:15px;
}

header
{
  background:#242424;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:flex-start;
}

.logo
{
  background:url(../../public/images/logo.svg);
  display:inline-block;
  width:193px;
  height:46px;
  background-repeat:no-repeat;
  background-size:contain;
}

.MuiInput-underline::before
{
  border-color:#DADCE0 !important;
}

.MuiBreadcrumbs-root
{
  padding-bottom:15px;
  border-bottom:solid 1px #DADCE0 !important;
}

.MuiContainer-root
{
  margin-left:0px !important;
  padding:0 15px !important;
}

.containerBtnTop
{
  display:flex !important;
  flex-direction:row;
  flex-wrap:wrap;
  align-items:center;
  align-content:center;
  padding:15px 0 !important;
}

.MuiAppBar-root
{
  flex-direction: row !important;
}

.addBtn
{
  position:absolute;
  right:-60px;
  z-index:10;
  margin:0px !important;
}
.removeBtn
{
  position:absolute;
  right:-26px;
  z-index:10;
  margin:0px !important;
}

.btnMultiItem
{
  width:0px !important;
  height:0px !important;
  padding:0px !important;
}

.addFromMenu
{
  color: #ffc400 !important;
}

.addFromMenuBtn
{
  border-radius: 48px !important;
  margin: 0px 8px 10px 8px !important;
  text-transform: none !important;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302),0 1px 3px 1px rgba(60,64,67,0.149);
}

.cursorPointer td
{
  cursor: pointer !important;
}

.cursorPointer th
{
  position: sticky !important;
}

.toolbarLogo
{
  position: static !important;
  width: 100% !important;
}

.drawerOCbtn
{
  border-radius: 100% !important;
  transition: ease-out all 0.5s !important;
  color: rgba(0,0,0,0.54) !important;
  position: fixed !important;
  z-index: 1200 !important;
  background: rgb(255, 196, 0) !important;
  border-radius: 100% !important;
}

.drawerOCbtn:hover
{
  background: rgb(255, 196, 0) !important;
}

.drawerOCbtn.OCclosed
{
 left: 75px;
  bottom:5px;
}

.drawerOCbtn.OCopened
{
  left:245px;
  bottom:5px;

}

.menuPrimary
{
  text-decoration: none !important;
  color:#5f6368 !important
}

.menuPrimaryActive
{
  text-decoration: none !important;
  background: #faebe5 !important;
  font-weight: bold !important;
  color: #c1564c !important;
}

.menuPrimary svg
{
  width: 20px !important;
  height: 20px !important;
}

.menuPrimaryActive svg
{
  fill: #c1564c !important;
}

.menuPrimaryText span
{
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
}

.MuiButton-root
{
  text-transform: none !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
}

.menuPrimarySecond
{
  font-size: 14px !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  color: #5f6368 !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 16px !important;
  padding-left: 32px !important;
  background: rgba(193, 86, 76, 0.05) !important;
}

.menuPrimarySecond a
{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.menuPrimarySecond.selected a
{
  color:#c1564c !important;
  display: block !important;
}

.menuPrimarySecondFirst a
{
  text-decoration: none !important;
  color: #5f6368 !important;
}

.menuPrimarySecond a
{
  text-decoration: none !important;
  color:rgba(0, 0, 0, 0.84) !important;
  display: block;
}

.active-link.menuPrimarySecond a, .active-link.menuPrimarySecondFirst a, .active-link.menuPrimarySecond, .active-link.menuPrimarySecondFirst
{
  color: #c1564c !important;
}

.active-link.menuPrimarySecond
{
  background: rgba(0, 0, 0, 0.05);
}

.menuPrimarySecondFirst
{
  font-size: 14px !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  color:rgba(0, 0, 0, 0.54) !important
}

.menuPrimarySecondFirst a
{
  display: block !important;
  width: 100%;
}

.active-link.menuPrimarySecondFirst
{
  color: #c1564c !important;
  background: #faebe5 !important;
  border-right: 3px solid #c1564c;
}

.addBtnGeneral
{
  font-size: 14px !important;
  font-weight: bold;
  text-decoration: none !important;
  color: #222 !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 3px 6px 0px, rgba(60, 64, 67, 0.15) 0px 3px 9px 3px !important;
  letter-spacing: 0.39998px !important;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background: rgb(255, 196, 0);
  border-radius: 70px;
  position: fixed;
  top: 76px;
  right: 24px;
  transition: all ease 0.5s;
  z-index: 1300;
}

.addBtnGeneral:hover
{
  opacity: 0.5;
}

.MuiFormControlLabel-root
{
  font-size: 13px !important;
  margin-bottom: 15px !important;
}

.MuiFormControlLabel-label
{
  font-size: 13px !important;
}

.breadcrumbs
{
  font-size: 14px !important;
}

.breadcrumbs a
{
  text-decoration: none;
  transition: all ease 0.5s;
}

.breadcrumbs a:hover
{
  opacity: 0.5;
}

.MuiTableCell-head
{
  line-height: 1 !important;
}

.LocationHintName
{
  line-height: 1.2 !important;
  font-size: 15px !important;
  display: block !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.LocationHintNameType
{
  line-height: 1 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 5px !important;
  color:#000 !important;
}

.MuiFormControlLabel-root.cheboxRoleACL
{
  margin:0px !important;
}